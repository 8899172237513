import React from 'react';
import {
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LogoImage from 'images/login_logo.svg'
import { useWindowWidth } from 'hooks/index.js';



export default function SignInSide() {

  const useStyles = makeStyles((theme) => ({
    root: {
      height: '100vh',
      background: '#EDEDED'
    },
    image: {
      backgroundColor: '#60A071',
      backgroundSize: 'cover',
    },
    logo: {
      marginBottom: '50px',
      '@media (max-width: 600px)': {
        marginBottom: '10px',
        display: 'block',
        marginLeft: '-20px',
        width: '80px',
      }
    },
    logoContainer: {
      '@media (min-width: 511px)': {
        height: 'calc(100% -  100px)'
      }
    },
    memberPortal: {
      color: '#5FA071',
      marginBottom: '20px',
      '@media (max-width: 600px)': {
        color: 'whitesmoke',
        fontSize: '48px',
        lineHeight: '48px'
      }
    },
    whiteColor: {
      color: "#FCFCFC",
    },
  }));

  const classes = useStyles();
  const width = useWindowWidth()

  return (
    <>
      <Grid container component="main" display="flex" className={classes.root}>
        <Grid item xs={12} sm={4} md={12} className={classes.image}>
          <Grid container justify={width > 680 ? 'center' : 'flex-start'} alignItems="center" className={classes.logoContainer}>
            <Box textAlign={width > 600 ? 'center' : 'left'} className={classes.left}>
              <img className={classes.logo} src={LogoImage} alt="logo" />
              <Box>
                <Typography component="h3" variant="h6" className={classes.whiteColor}>
                  Commonwealth's member portal has been taken offline. Please reach out to <a href='mailto:contact@senary.xyz'>contact@senary.xyz</a> with any questions. Thank you.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid container justify="center">
            <Box component="div">
              {width > 600 && <Typography component="span" variant="body2" className={classes.whiteColor}>
                © 2021 Senary Commonwealth. All rights reserved.
              </Typography>
              }
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
